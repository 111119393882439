<template>
    <v-row no-gutters class="d-block mt-3">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-card>
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12">
                                <v-alert dense type="info" outlined class="text-center">
                                    Si cambia el N.° de Identidad, debe actualizar las Credenciales de Acceso.
                                </v-alert>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field
                                    v-model="personaModel.numero_identidad"
                                    :label="personaForm.numero_identidad.label"
                                    :placeholder="personaForm.numero_identidad.placeholder"
                                    :rules="personaForm.numero_identidad.rules"
                                    required
                                    prepend-icon="fingerprint"
                                />

                                <v-text-field
                                    v-model="personaModel.nombre"
                                    :label="personaForm.nombre.label"
                                    :placeholder="personaForm.nombre.placeholder"
                                    :rules="personaForm.nombre.rules"
                                    required
                                    prepend-icon="perm_identity"
                                />

                                <v-text-field
                                    v-model="personaModel.apellido"
                                    :label="personaForm.apellido.label"
                                    :placeholder="personaForm.apellido.placeholder"
                                    :rules="personaForm.apellido.rules"
                                    required
                                    prepend-icon="perm_identity"
                                />

                                <v-row>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="personaModel.cat_sexo_id"
                                            :items="catSexos"
                                            item-text="nombre"
                                            item-value="cat_sexo_id"
                                            :label="personaForm.cat_sexo_id.label"
                                            :placeholder="personaForm.cat_sexo_id.placeholder"
                                            :rules="personaForm.cat_sexo_id.rules"
                                            required
                                            prepend-icon="checklist"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            type="number"
                                            v-model="personaModel.edad"
                                            :label="personaForm.edad.label"
                                            :placeholder="personaForm.edad.placeholder"
                                            prepend-icon="pin"
                                        />
                                    </v-col>
                                </v-row>

                                <v-text-field
                                    v-model="personaModel.departamento"
                                    :label="personaForm.departamento.label"
                                    :placeholder="personaForm.departamento.placeholder"
                                    prepend-icon="location_city"
                                />
                                <v-text-field
                                    v-model="personaModel.municipio"
                                    :label="personaForm.municipio.label"
                                    :placeholder="personaForm.municipio.placeholder"
                                    prepend-icon="location_city"
                                />

                                <v-text-field
                                    v-model="personaModel.ciudad"
                                    :label="personaForm.ciudad.label"
                                    :placeholder="personaForm.ciudad.placeholder"
                                    prepend-icon="apartment"
                                />

                                <v-text-field
                                    v-model="personaModel.direccion"
                                    :label="personaForm.direccion.label"
                                    :placeholder="personaForm.direccion.placeholder"
                                    prepend-icon="home"
                                />


                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">

                                <v-text-field
                                    v-model="personaModel.correo"
                                    :label="personaForm.correo.label"
                                    :placeholder="personaForm.correo.placeholder"
                                    prepend-icon="email"
                                />

                                <v-text-field
                                    v-model="personaModel.telefono"
                                    :label="personaForm.telefono.label"
                                    :placeholder="personaForm.telefono.placeholder"
                                    prepend-icon="phone"
                                />

                                <v-text-field
                                    v-model="personaModel.tipo_participante"
                                    :label="personaForm.tipo_participante.label"
                                    :placeholder="personaForm.tipo_participante.placeholder"
                                    prepend-icon="checklist"
                                />

                                <v-text-field
                                    v-model="personaModel.nivel_educativo"
                                    :label="personaForm.nivel_educativo.label"
                                    :placeholder="personaForm.nivel_educativo.placeholder"
                                    prepend-icon="school"
                                />
                                <v-text-field
                                    v-model="personaModel.lugar_de_trabajo"
                                    :label="personaForm.lugar_de_trabajo.label"
                                    prepend-icon="work"
                                />

                                <v-text-field
                                    v-model="personaModel.direccion_trabajo"
                                    :label="personaForm.direccion_trabajo.label"
                                    :placeholder="personaForm.direccion_trabajo.placeholder"
                                    prepend-icon="maps_home_work"
                                />

                                <v-text-field
                                    v-model="personaModel.telefono_trabajo"
                                    :label="personaForm.telefono_trabajo.label"
                                    :placeholder="personaForm.telefono_trabajo.placeholder"
                                    prepend-icon="phone"
                                />

                                <v-row>
                                    <v-col cols="3">
                                        <!-- Que solo sea visible desde Editar-->
                                        <v-switch v-show="$route.query.token"
                                                  v-model="personaModel.activo"
                                                  :label="personaForm.activo.label"
                                                  color="success"
                                                  class="font-weight-bold"
                                                  inset
                                                  large
                                                  hide-details
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="9">
                                    </v-col>
                                </v-row>

                            </v-col>

                        </v-row>

                    </v-form>
                </v-card-text>
                <v-divider class="mt-1"></v-divider>
                <v-card-actions class="justify-center py-3">
                    <v-btn outlined @click="regresar" small>
                        <v-icon>arrow_back</v-icon>
                        Regresar
                    </v-btn>

                    <v-btn outlined color="warning" @click="reset" small>
                        <v-icon>restart_alt</v-icon>
                        Limpiar
                    </v-btn>

                    <v-btn outlined small color="error" @click="deleteBefore"
                           v-if="permisos[4] && personaModel.persona_id">
                        <v-icon>delete</v-icon>
                        Eliminar
                    </v-btn>

                    <v-btn color="primary" :loading="loading" @click="personaSave" small>
                        <v-icon>save</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-bottom-sheet
            v-model="deleteConfirm"
            persistent
        >
            <v-sheet
                class="text-center"
                height="200"
            >

                <div class="py-4">
                    <h2 class="red--text mb-3">¿Esta realmente seguro de ELIMINAR este Estudiante.?</h2>
                    <h4> Esta operación es irreversible. El Estudiante y todos sus Cursos serán ELIMINADOS.</h4>
                </div>
                <v-card-actions class="justify-center pb-3">
                    <v-btn outlined small @click="deleteConfirm = false">
                        <v-icon>hide_source</v-icon>
                        No lo eliminaré
                    </v-btn>
                    <v-btn outlined small color="error" @click="personaDelete">
                        <v-icon>delete</v-icon>
                        Si, Estoy Seguro de Eliminar
                    </v-btn>
                </v-card-actions>

            </v-sheet>
        </v-bottom-sheet>
    </v-row>

</template>

<script>

export default {
    name: "ParticipantesEstudiantesFormulario",
    props: {
        // Si es requerido falso, que sea Default Vacio
        getParams: {
            type: Object,
            required: false,
        }
    },
    data() {
        return {
            valid: true,
            loading: false,
            catSexos: [],
            permisos: this.getParams.permisos,
            deleteConfirm: false,
            personaModel: {
                persona_id: null,
                numero_identidad: null,
                nombre: null,
                apellido: null,
                correo: null,
                edad: null,
                cat_sexo_id: null,
                telefono: null,
                nivel_educativo: null,
                departamento: null,
                municipio: null,
                ciudad: null,
                direccion: null,
                tipo_participante: null,
                lugar_de_trabajo: null,
                direccion_trabajo: null,
                telefono_trabajo: null,
                activo: true,
                cat_tipo_persona_id: this.getParams.cat_tipo_persona_id,
            },
            personaForm: {
                numero_identidad: {
                    label: 'Número de identidad',
                    placeholder: 'DNI (Cédula)',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                nombre: {
                    label: 'Nombres',
                    placeholder: 'Apellidos',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                apellido: {
                    label: 'Apellidos',
                    placeholder: 'Apellidos',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                cat_sexo_id: {
                    label: 'Sexo',
                    placeholder: 'Seleccione',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                edad: {
                    label: 'Edad',
                    placeholder: 'Edad',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                correo: {
                    label: 'Correo',
                    placeholder: 'nombre@correo.com',
                    rules: [
                        (v) => !!v || 'Campo requerido',
                        (v) => /.+@.+/.test(v) || 'Formato invalido'
                    ],
                },
                telefono: {
                    label: 'Teléfono',
                    placeholder: 'Teléfono',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                departamento: {
                    label: 'Departamento',
                    placeholder: 'Departamento',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                municipio: {
                    label: 'Municipio',
                    placeholder: 'Municipio',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                ciudad: {
                    label: 'Ciudad',
                    placeholder: 'Ciudad',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                direccion: {
                    label: 'Dirección',
                    placeholder: 'Dirección',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                nivel_educativo: {
                    label: 'Nivel Educativo',
                    placeholder: 'Nivel Educativo',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                lugar_de_trabajo: {
                    label: 'Lugar de Trabajo',
                    placeholder: 'Lugar de Trabajo',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                telefono_trabajo: {
                    label: 'Teléfono de Trabajo',
                    placeholder: 'Teléfono de Trabajo',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                direccion_trabajo: {
                    label: 'Dirección de Trabajo',
                    placeholder: 'Dirección de Trabajo',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                tipo_participante: {
                    label: 'Tipo de Participante',
                    placeholder: 'Tipo de Participante',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                activo: {
                    label: 'Activo',
                },
            },

        }
    },
    computed: {
        // DO some
    },
    methods: {
        regresar() {
            this.$router.go(-1)
        },
        goToEdit() {
            let token = window.btoa(this.personaModel.persona_id);
            this.$router.push({name: 'ParticipantesEstudiantesEditar', query: {token}});
        },
        reset() {
            // Validar si estamos editando
            if (Number(this.personaModel.persona_id)) {
                this.getPersonaEdit();
            } else {
                this.$refs.form.reset();
            }

        },
        getPersonaCreate() {
            this.loading = true;
            // Get data;
            window.axios.get('/personas/create', {}).then(response => {
                if (response.data.success) {

                    this.catSexos = response.data.data.catSexos;

                    // Si vienen los datos, llamar al edit
                    this.getPersonaEdit();

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo obtener los datos para continuar..");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
        },
        getPersonaEdit() {
            // Validar operaciones
            if (Number(this.getParams.persona_id)) {
                this.loading = true;
                this.personaModel.persona_id = this.getParams.persona_id;

                let params = {persona_id: this.personaModel.persona_id}

                window.axios.get('/personas/edit', {params}).then(response => {
                    if (response.data.success) {

                        if (response.data.data.length) {
                            this.personaModel = response.data.data[0];
                        } else {
                            this.$toast.error("No se encontraron los datos de la Persona.");
                        }

                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo obtener la información de la Persona.");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            }
        },
        personaSave() {
            // Validar
            if (this.$refs.form.validate()) {
                this.loading = true;
                let url = (Number(this.personaModel.persona_id)) ? '/personas/update' : '/personas/store';


                window.axios.post(url, this.personaModel).then(response => {
                    if (response.data.success) {

                        this.$toast.success(response.data.message);

                        if (Number(this.personaModel.persona_id)) {
                            this.getPersonaEdit();
                        } else {
                            // MANDAR a la interfaz de EDITAR
                            this.personaModel.persona_id = response.data.data;
                            this.goToEdit();
                        }

                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo guardar.");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            } else {
                this.$toast.warning("Complete toda la información requerida.");
            }

        },
        deleteBefore() {
            // Activar el DELETE
            this.deleteConfirm = true;
        },
        personaDelete() {
            // Validar
            let url = '/personas/delete';
            window.axios.post(url, this.personaModel).then(response => {
                if (response.data.success) {

                    this.$toast.success(response.data.message);

                    this.regresar();

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo Eliminar.");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
        },
    },
    created() {
        this.getPersonaCreate();
    }
}
</script>

<style>

</style>
